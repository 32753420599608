// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// No need for this because it's being imported from cdn
// import $ from 'jquery';

import foundation from 'foundation-sites';
import 'jquery-match-height';

import Header from '../_modules/header/header';
import Footer from '../_modules/footer/footer';
import Contact1 from '../_modules/contact1/contact1';
import Contact2 from '../_modules/contact2/contact2';
import Contact3 from '../_modules/contact3/contact3';
import Contact4 from '../_modules/contact4/contact4';

$(() => {
	new Header();
	new Footer();
	new Contact1();
	new Contact2();
	new Contact3();
	new Contact4();
	new svg4everybody({
	    polyfill: true // polyfill <use> elements for External Content
	});

	$('.header__menu-main__list__enclosure').hover(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 980){
			$(this).toggleClass('active');
		    //$('.header__menu-main__list__enclosure--default').toggleClass('active');
		}
	});

	$('.header__menu-main__list__enclosure__title').click(function(e){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 980){
			if (e.target !== this){
				return;
			}
			
			if($(this).hasClass('mobile-active')){
				$('.header__menu-main__list__enclosure__title').removeClass('mobile-active');
			}
			else{
				$('.header__menu-main__list__enclosure__title').removeClass('mobile-active');
				$(this).addClass('mobile-active');
			}
		}
	});

	// Open and close lists by adding/removing active class to sidenav lists
	$('.sidebar__list__enclosure__text').click(function(){
		if($(this).parent().next().hasClass('sidebar__list')){
			$(this).parent().next().slideToggle();
			$(this).parent().toggleClass('active');
		}
	});

	// Open header liquor menu level 2
	$('li.header__liquor__list__enclosure[data-level="1"]').click(function(){
		if(!$(this).children('a').length){
			$('li.header__liquor__list__enclosure[data-level="1"]').removeClass('selected');
			$('li.header__liquor__list__enclosure[data-level="2"]').removeClass('selected');
			$(this).addClass('selected');
			$('.header__liquor__list__title-container-level-2').css('display', 'block');
			$('.header__text--small-bold.header__liquor__list__title[data-titlelevel="2"]').text($(this).text());
			$('.header__text--small-bold.header__liquor__list__title[data-titlelevel="3"]').text('');
			$('.header__menu-liquor__container [data-level="2"]').css('display', 'none');
			$('.header__menu-liquor__container [data-level="3"]').css('display', 'none');
			$('ul.header__liquor__list[data-level="2"][data-level1item="' + $(this).data('item') + '"]').animate({width: 'toggle'});
			$('li.header__liquor__list__enclosure[data-level="2"][data-level1item="' + $(this).data('item') + '"]').css('display', 'flex');
			setTimeout(function() {
		        $(document).resize();
		    }, 300);
		}
	});

	// Open header liquor menu level 3
	$('li.header__liquor__list__enclosure[data-level="2"]').click(function(){
		if(!$(this).children('a').length){
			$('li.header__liquor__list__enclosure[data-level="2"]').removeClass('selected');
			$(this).addClass('selected');
			$('.header__liquor__list__title-container-level-3').css('display', 'block');
			$('.header__text--small-bold[data-titlelevel="3"]').text($(this).text());
			$('.header__menu-liquor__container [data-level="3"]').css('display', 'none');
			$('ul.header__liquor__list[data-level="3"][data-level1item="' + $(this).data('level1item') + '"][data-level2item="' + $(this).data('item') + '"]').animate({width: 'toggle'});
			$('li.header__liquor__list__enclosure[data-level="3"][data-level1item="' + $(this).data('level1item') + '"][data-level2item="' + $(this).data('item') + '"]').css('display', 'flex');
			setTimeout(function() {
		        $(document).resize();
		    }, 300);
		}
	});

	// Open header gambling menu level 2
	$('li.header__gambling__list__enclosure[data-level="1"]').click(function(){
		if(!$(this).children('a').length){
			$('li.header__gambling__list__enclosure[data-level="1"]').removeClass('selected');
			$('li.header__gambling__list__enclosure[data-level="2"]').removeClass('selected');
			$(this).addClass('selected');
			$('.header__gambling__list__title-container-level-2').css('display', 'block');
			$('.header__text--small-bold.header__gambling__list__title[data-titlelevel="2"]').text($(this).text());
			$('.header__text--small-bold.header__gambling__list__title[data-titlelevel="3"]').text('');
			$('.header__menu-gambling__container [data-level="2"]').css('display', 'none');
			$('.header__menu-gambling__container [data-level="3"]').css('display', 'none');
			$('ul.header__gambling__list[data-level="2"][data-level1item="' + $(this).data('item') + '"]').animate({width: 'toggle'});
			$('li.header__gambling__list__enclosure[data-level="2"][data-level1item="' + $(this).data('item') + '"]').css('display', 'flex');
			setTimeout(function() {
		        $(document).resize();
		    }, 300);
		}
	});

	// Open header gambling menu level 3
	$('li.header__gambling__list__enclosure[data-level="2"]').click(function(){
		if(!$(this).children('a').length){
			$('li.header__gambling__list__enclosure[data-level="2"]').removeClass('selected');
			$(this).addClass('selected');
			$('.header__gambling__list__title-container-level-3').css('display', 'block');
			$('.header__text--small-bold[data-titlelevel="3"]').text($(this).text());
			$('.header__menu-gambling__container [data-level="3"]').css('display', 'none');
			$('ul.header__gambling__list[data-level="3"][data-level1item="' + $(this).data('level1item') + '"][data-level2item="' + $(this).data('item') + '"]').animate({width: 'toggle'});
			$('li.header__gambling__list__enclosure[data-level="3"][data-level1item="' + $(this).data('level1item') + '"][data-level2item="' + $(this).data('item') + '"]').css('display', 'flex');
			setTimeout(function() {
		        $(document).resize();
		    }, 300);
		}
	});

	$('.footer__gotop').click(()=>{
		$('html, body').animate({scrollTop: 0});
	});

	// Maintain liquor and gambling header menu title height across all 3 columns
	// Initial
	$('.header__liquor__list__title-container-level-2').height($('.header__liquor__list__title-container-level-1').height());
	$('.header__liquor__list__title-container-level-3').height($('.header__liquor__list__title-container-level-1').height());
	$('.header__gambling__list__title-container-level-2').height($('.header__gambling__list__title-container-level-1').height());
	$('.header__gambling__list__title-container-level-3').height($('.header__gambling__list__title-container-level-1').height());
	// On resize
	$(window).resize(function(){
		$('.header__liquor__list__title-container-level-2').height($('.header__liquor__list__title-container-level-1').height());
		$('.header__liquor__list__title-container-level-3').height($('.header__liquor__list__title-container-level-1').height());
		$('.header__gambling__list__title-container-level-2').height($('.header__gambling__list__title-container-level-1').height());
		$('.header__gambling__list__title-container-level-3').height($('.header__gambling__list__title-container-level-1').height());
	});

	if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 980){
		$('.sidebar__list-start').slideUp();
		$('.section__container').addClass('active');
	}
	// Slide the mobile menu up (hidden) 
	$(window).resize(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 980){
			$('.sidebar__list-start').slideUp();
			$('.section__container').addClass('active');
		}
	});

	$('.section__container').click(function(){
		$('.sidebar__list-start').slideToggle();
		$(this).toggleClass('active');
	});

	$('.header__menu-help__input__search__img').click(function(){
		$('.header__menu-help__form').submit();
	});

	$(window).resize(function(){
		$('.is-drilldown').css('max-width', window.innerWidth);
	});

    // Open mobile search menu
    $('.header--mobile-icon__search').click(function(){
        $('.mobile__search__menu').fadeIn(100);
        $('.container.overlay').addClass('overlay-active');
    });

    // Close mobile search menu
    $('.mobile__search__close').click(function(){
        $('.mobile__search__menu').fadeOut(300);
        $('.container.overlay').removeClass('overlay-active');
    });

    // Open mobile nav menu
    $('.header--mobile-icon__hamburger').click(function(){
        $('.mobile__nav__menu').fadeIn(100);
        $('.container.overlay').addClass('overlay-active');
    });

    // Close mobile nav menu
    $('.mobile__nav__close').click(function(){
        $('.mobile__nav__menu').fadeOut(300);
        $('.container.overlay').removeClass('overlay-active');
    });

    // Third level content for content pages
    $('.standard__dropdown__toggle').click(function(){
    	$(this).next('.standard__dropdown__content__container').slideToggle();
    	$(this).toggleClass('active');
    });

    // Homepage panel show text on hover
	$('.panel__link').mouseenter(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1024){
	        $(this).find('.panel--small__img__wrapper').stop().slideToggle();
	        $(this).find('.panel--small__text-peak').stop().fadeToggle();
	    }
    }).mouseleave(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1024){
	        $(this).find('.panel--small__img__wrapper').stop().slideToggle();
	        $(this).find('.panel--small__text-peak').stop().fadeToggle();
	    }
    });

    // News listing show text on hover
	$('.news__link').mouseenter(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1024){
	        $(this).find('.news--small__img__wrapper').stop().slideToggle();
	        $(this).find('.news--small__text-peak').stop().fadeToggle();
	    }
    }).mouseleave(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1024){
	        $(this).find('.news--small__img__wrapper').stop().slideToggle();
	        $(this).find('.news--small__text-peak').stop().fadeToggle();
	    }
    });

    // News related show text on hover
	$('.news__related__link').mouseenter(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1024){
	        $(this).find('.news__related--small__img__wrapper').stop().slideToggle();
	        $(this).find('.news__related--small__text-peak').stop().fadeToggle();
	    }
    }).mouseleave(function(){
		if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1024){
	        $(this).find('.news__related--small__img__wrapper').stop().slideToggle();
	        $(this).find('.news__related--small__text-peak').stop().fadeToggle();
	    }
    });

    $('.container.overlay').click(function(){
    	$(this).removeClass('overlay-active');
    	$('.mobile__search__menu').fadeOut();
    	$('.mobile__nav__menu').fadeOut();
    });

    // Displaying different lists for contact wizard
    $('.contact__wizard__list__enclosure').click(function(event){
    	event.stopPropagation();
    	if(!$(this).parent().hasClass('level-3')){
	    	if ($(this).parent().hasClass('level-1')){
	    		$('.contact__wizard__list__enclosure').removeClass('active');
	    		$('.contact__wizard__list.level-2').fadeOut();
	    		$('.contact__wizard__list.level-3').fadeOut();
	    	}
	    	$(this).addClass('active').parent().addClass('active');
	    	$(this).find('> .contact__wizard__list').css("display", "flex").hide().fadeIn();

	    	// Adjust margin of the next row so the wizard does not block the form
	    	if ($(this).find('> .contact__wizard__list').height()){
	    		$('.contact__wizard__container').css({ 'margin-bottom': $(this).find('> .contact__wizard__list').height() + 30 });
	    	}
	    	else{
	    		$('.contact__wizard__container').css({ 'margin-bottom': '0' });
	    	}
	    }
    });

    // Back button for contact wizard
    $('.contact__back__container').click(function(event){
    	event.stopPropagation();
    	$(this).parents('.contact__wizard__list.level-3').fadeOut();
    });

    // Show checkbox options when clicking taxonomy parent
    $('.listing__filter__taxonomy__parent').click(function(event){
    	if (event.target !== this){
			return;
		}
		
    	if($(this).hasClass('active')) {
    		$(this).removeClass('active');
    	}
    	else {
			$('.listing__filter__taxonomy__parent').removeClass('active');
	    	$(this).toggleClass('active');
    	}
    });

    // Remove taxonomy when clicked using event delegation
    $( ".listing__filter__container--tags" ).on( "click", ".listing__filter__tag", function() {
	    $(this).remove();
	    $('#' + $(this).data('checkbox')).prop('checked', false);
	});

    // Add / Remove tags when checking / unchecking checkboxes
    $('.listing__filter__checkbox').change(function(){
		if($(this).is(':checked')){
			$('.listing__filter__container--tags').append('<div class="listing__filter__tag" data-checkbox="' + $(this).attr('id') + '"><div class="listing__filter__tag__text">' + $(this).find('+ label').text() + '</div><img src="/sites/all/themes/vcglr/images/close.svg" class="listing__filter__close"></div>');
		} else {
			$('.listing__filter__tag[data-checkbox="' + $(this).attr('id') + '"]').remove();
		}
	});

    // Close button for checkbox container
	$('.listing__filter__container--close-inner').click(function(){
		$(this).parents('.listing__filter__taxonomy__parent').removeClass('active');
	});

	// For prototype only
	$('.news__filter__list__enclosure').click(function(){
		if(!$(this).hasClass('news__filter__list__enclosure--input')){
			$('.news__filter__list__enclosure').removeClass('active');
			$(this).addClass('active');
		}
	});

	// For prototype only
	$('.listing__filter__btn').click(function(){
		window.location.href = "/listing.html";
	});

	// Alter twitter share text depending on which button was clicked
	$('a.addthis_button_twitter').click(function(){
		addthis_share = {
			passthrough : {
				twitter: {
					text: "\"" + $(this).find('p').text() + "\""
				}
			}
		}
	});

	$('.contact__wizard li').click(function(){
		if($(this).attr('data-target')){
			var target = $('ul[data-name="' + $(this).data('target') + '"]')
			target.siblings().hide();
			target.parent().nextAll().find('> ul').hide();
			target.parent().siblings('.level-2, .level-3, .level-4').find('> ul').hide();
			target.fadeIn();

			// Hide form
			$('.contact__row--form').css('display', 'none');

			// If final node, show form
			if(target.attr('data-path')){
				$('.contact__row--form').css('display', 'block');
				$('.contact__form__path').val(target.data('path'));
				$('.contact__form__path').text(target.data('path'));
				$('#edit-submitted-enquiry-type option[value="' + target.data('name') + '"]').prop('selected', true);
			}

			if($(this).parents('.level-1').length){
				$(this).siblings().removeClass('active').addClass('faded');
				$(this).removeClass('faded').addClass('active');
				$(this).parents('.level-1').addClass('active');
			}

			// Hide "Click here to find out what types of enquiries you should contact us about"
			$('.contact__row--cta').hide();
		}
	});

	$('.contact__wizard__back').click(function(){
		var name = $(this).parents('ul[data-name]').data('name');
		$('ul[data-name="' + name + '"]').hide();
		$('li[data-target="' + name + '"]').parent('ul').fadeIn();

		if($(this).parents('.level-2').length){
			$('.level-1').removeClass('active');
			$('.level-1 li[data-target]').removeClass('active');
		}

		// Hide form
		$('.contact__row--form').css('display', 'none');
	});

	$('.contact__tab').click(function(){
		var index = $('.contact__tabs__container li').index($(this));
		
		$(this).siblings().removeClass('active');
		$(this).addClass('active');

		$(".contact__tabs__select")[0].selectedIndex = index;
		
		$('.contact__tabs__container').find('.contact__tab__contents').find('.contact__tab__content').not('.contact__tab__content:eq(' + index + ')').removeClass('active');
		$('.contact__tabs__container').find('.contact__tab__contents').find('.contact__tab__content:eq(' + index + ')').addClass('active');
	});

	$('.contact__tabs__select').change(function(){
		var index = $(this)[0].selectedIndex;
		$('.contact__tab').removeClass('active');
		$('ul.contact__tabs').find('.contact__tab:eq(' + index + ')').addClass('active');
		
		$('.contact__tabs__container').find('.contact__tab__contents').find('.contact__tab__content').not('.contact__tab__content:eq(' + index + ')').removeClass('active');
		$('.contact__tabs__container').find('.contact__tab__contents').find('.contact__tab__content:eq(' + index + ')').addClass('active');
	});

	$('.feedback__link').click(function(){
		$('li[data-target="website"]').click();
	});

});
